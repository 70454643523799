<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-olive pointer" v-on:click="$router.push('/tasks/funnel/main')">
      <div class="inner">
        <h3>
          {{ main_tasks_count }}
          <small v-if="main_incoming_tasks > 0" class="text-white font-scale-1">
            (+{{ main_incoming_tasks }})
          </small>
        </h3>
        <h5>Все задачи</h5>
      </div>
      <div class="icon">
        <i class="fas fa-file-invoice"></i>
      </div>
      <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fa font-scale fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tasks-box-item',

  props: {
    main_tasks_count: {
      type: Number,
      required: true,
      default: 0,
    },
    main_incoming_tasks: {
      type: Number,
      required: true,
      default: 0,
    },
  }
}
</script>
